import NAV_CONTENT_COLORS from './constants/navContentColors';

const EXPANSION_PANEL_LEVELS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  FOOTER: 'footer',
};

const defaultSpacer = {
  '@type': '@builder.io/sdk:Element',
  component: {
    name: 'Spacer',
  },
};

const ExpansionPanelBuilderComponent = {
  name: '_ExpansionPanel_',
  inputs: [
    {
      name: 'expansionPanelLevel',
      friendlyName: 'Expansion Panel Level',
      helperText:
        'Primary variation is styled for T1 menu options and Secondary variation is styled for T2 menu items',
      type: 'string',
      enum: [
        EXPANSION_PANEL_LEVELS.PRIMARY,
        EXPANSION_PANEL_LEVELS.SECONDARY,
        EXPANSION_PANEL_LEVELS.FOOTER,
      ],
      defaultValue: EXPANSION_PANEL_LEVELS.PRIMARY,
      localized: false,
    },
    {
      name: 'panels',
      type: 'list',
      copyOnAdd: false,
      defaultValue: [
        {
          panelLabel: 'Women',
          expandPanelByDefault: false,
          panelLabelColor: NAV_CONTENT_COLORS.DEFAULT,
          panelContent: [defaultSpacer],
        },
        {
          panelLabel: 'Men',
          expandPanelByDefault: false,
          panelLabelColor: NAV_CONTENT_COLORS.DEFAULT,
          panelContent: [defaultSpacer],
        },
      ],
      subFields: [
        {
          name: 'panelLabel',
          friendlyName: 'Panel Label',
          helperText: 'The title label of the individual panel.',
          type: 'string',
          defaultValue: 'Panel Label',
          localized: true,
          required: true,
        },
        {
          name: 'expandPanelByDefault',
          friendlyName: 'Panel Expanded by Default',
          helperText:
            'If on, panel is expanded by default. An example of this is using ExpansionPanel in a mobile hamburger menu and if this is true, the panel is open by default after opening the mobile hamburger menu.',
          type: 'boolean',
          defaultValue: false,
          localized: true,
        },
        {
          name: 'panelLabelColor',
          friendlyName: 'Panel Label Color',
          helperText: 'Color of panel label.',
          type: 'string',
          enum: [NAV_CONTENT_COLORS.DEFAULT, NAV_CONTENT_COLORS.SALE],
          defaultValue: NAV_CONTENT_COLORS.DEFAULT,
          localized: true,
        },
        {
          name: 'dataAnalytics',
          friendlyName: 'Data Analytics',
          type: 'object',
          subFields: [
            {
              name: 'label',
              friendlyName: 'Analytics Label',
              helperText:
                'Label provided through to analytics services. Example: T2_Men_Bottoms',
              type: 'string',
            },
            {
              name: 'category',
              friendlyName: 'Analytics Category',
              helperText:
                'Category provided through to analytics services. Example: navigation',
              type: 'string',
            },
          ],
        },
        {
          name: 'panelContent',
          friendlyName: 'Panel Content',
          helperText: 'Content displayed once the panel is expanded.',
          type: 'uiBlocks',
          defaultValue: [defaultSpacer],
        },
      ],
    },
  ],
} as const;

export default { ExpansionPanelBuilderComponent, EXPANSION_PANEL_LEVELS };
